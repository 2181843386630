<template>
  <div id="page-advanced-search">
    <h5 class="mb-6 ml-2">{{$t('PAGES.SEARCH.ADVANCED_SEARCH')}}</h5>

    <!--<AdvancedSearchQuerySelectView />-->

    <AdvancedSearchView 

    />

  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import AdvancedSearchView from '@/view/pages/ml/advanced_search/AdvancedSearchView.vue';
//import AdvancedSearchQuerySelectView from './advanced_search/AdvancedSearchQuerySelectView.vue';

export default {
  name: 'advancedSearch',
  mixins: [ toasts ],
  components: {
    AdvancedSearchView,
    //AdvancedSearchQuerySelectView
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  data() {
    return {
      noPermission: false,
      member_id: null,
      showMemberEditor: false,
      company_id: null,
      period_id: null,
      search_button_disabled: false,
      selectedSearch: null,
      searchHistory: [{ value: null, text: this.$t('PAGES.SEARCH.SELECT_SEARCH') }],
      regions: [],
      countries: [],
      genders: [
        { value: 'M', text: this.$t('COMMON.MAN') },
        { value: 'F', text: this.$t('COMMON.WOMAN') },
        { value: 'O', text: this.$t('COMMON.OTHER') },
        { value: 'U', text: this.$t('COMMON.UNKNOWN') }
      ],
      payments: [
        { value: 1, text: this.$t('COMMON.PAID') },
        { value: 0, text: this.$t('COMMON.NOT_PAID') }
      ],
      ladoks: [
        { value: 'Y', text: this.$t('COMMON.LADOK_STUDENT_STATUS.Y') },
        { value: 'N', text: this.$t('COMMON.LADOK_STUDENT_STATUS.N') },
        //{ value: 'S', text: this.$t('COMMON.LADOK_STUDENT_STATUS.S') },
        { value: 'P', text: this.$t('COMMON.LADOK_STUDENT_STATUS.P') }
      ],
      
      members: [],
      columns: [],
      constreq: []
    };
  },
  async mounted() {

    //this.showHelpToast('advanced-search');

    const loader = this.$loading.show();
    // get regions
    const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');
    if (res.status === 200) {
      // region load
      for (const [k, region] of Object.entries(res.data.regions)) {
        const communes = [];
        for (const [key, comm] of Object.entries(region.communes)) {
          communes.push({ value: key, text: comm });
        }
        communes.sort((a, b) => {
          if (a.text < b.text) { return -1; }
          if (a.text > b.text) { return 1; }
          return 0;
        });
        this.regions.push({
          text: region.region,
          value: region.region_code,
          communes: communes
        });
        this.regions.sort((a, b) => {
          if (a.text < b.text) { return -1; }
          if (a.text > b.text) { return 1; }
          return 0;
        });
      }
    } else {
      // no region found
    }
    // country
    const res1 = await axios.get('/location/countries');
    if (res1.status === 200) {
      this.countries = res1.data;
    } else {
    }
    // get constreq
    const res3 = await axios.get(`/company/constreq`);
    if (res3.status === 200) {
      this.constreq = res3.data.filter(c => c.value);
    } else {
    }
    // periods
    loader.hide();
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      this.showMemberEditor = false;
      this.members = [];
    },
    currentPeriodId(newValue, oldvalue) {
      this.showMemberEditor = false;
      this.members = [];
    }
  },
  methods: {
    memberSelected(member) {
      this.member_id = member.id;
      this.company_id = member.company_id;
      this.period_id = member.period_id;
      this.showMemberEditor = true;
      this.scrollToClassName('create-member-section');
    },
    scrollToClassName(classname) {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    queryCreated(query) {
      this.searchHistory.push({ value: query.query_id, text: query.name });
    },
    querySelected(query_id) {
      if (query_id) {
        axios
          .post('/search/byquery', { query_id, company_id: this.currentCompanyId })
          .then(res => {
            this.noPermission = res.status === 403;
            if (res.status === 200) {
              const items = res.data;
              this.members = items.map(item => {
                
                item.rdt = dayjs(item.rdt).format('YYYY-MM-DD');
                return item;
              });
              this.scrollToClassName('search-result-scrollto');
            }
            if (res.status === 500) {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_SEARCH'));
            }
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.SELECTION_NOT_FOUND'));
          });
      } else {
        this.noPermission = false;
        this.members = [];
      }
    },
    onSearch(query) {
      if (!query.search_members && !query.search_users) {
        this.toastr(
          'warning',
          this.$t('COMMON.ERROR'),
          this.$t('PAGES.SENDOUT.SELECT_TYPE')
        );
        return;
      }
      const loader = this.$loading.show();
      this.search_button_disabled = true;

      axios
        .post('/search/direct', query)
        .then(res => {
          this.noPermission = res.status === 403;
          if (res.status === 200) {
            const items = res.data;
            // const members = [...res.data.users, ...res.data.members];
            this.members = items.map(item => {
              if (item.not_member === 1) {
                const non_member_item = {};
                const non_member_data = JSON.parse(item.non_member_data);

                non_member_item.company = this.$t('MEMBER.NON_MEMBER');
                non_member_item.firstname = non_member_data.firstname;
                non_member_item.lastname = non_member_data.lastname;
                non_member_item.email = non_member_data.email;
                non_member_item.phone = non_member_data.phone;
                non_member_item.unviewable = true;

                return non_member_item;
              }

              item.rdt = dayjs(item.rdt).format('YYYY-MM-DD');
              return item;
            });
            this.scrollToClassName('search-result-scrollto');
          }
          if (res.status === 500) this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_SEARCH'));
          this.search_button_disabled = false;
          loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.search_button_disabled = false;
          loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_SEARCH'));
        });
    },
    
    setExcelColumns(val) {
      this.columns = val;
    },
    async export_member_letter_labels() {
      try {
        var query = this.$refs['searchOptions'].createQuery();
        const res = await axios.post('/export/letter_labels/member/query', {
          query: query,
          company_id: this.currentCompanyId
        });

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));  
        }
      }
      catch (err) {
        console.error('export_member_letter_labels', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
    },
    exportExcel(e) {
      e.preventDefault();

      var query = this.$refs['searchOptions'].createQuery();
      axios
        .post('/search/export', {
          query: query,
          columns: this.columns,
          company_id: this.currentCompanyId
        })
        .then(res => {

          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        });
    },
    exportCSV() {},
    onCloseMemberEditor() {
      this.member_id = null;
      this.showMemberEditor = false;
    },
    createMember() {

      this.showMemberEditor = true;
      this.member_id = null;
      this.form = {};
      
      this.$nextTick(()=>{
        this.$refs['memberEditor'].resetForNewMember();
      });
    },
    deleteMember() {
      axios
        .delete(`/member/${this.member_id}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_DELETED'));
            this.members = this.members.filter(item => this.member_id !== item.member_id);
            this.onCloseMemberEditor();
            this.scrollToClassName('search-result-scrollto');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE'));
        });
    }
  }
};
</script>
